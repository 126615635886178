import { z } from 'zod';

import { RequiredString } from './common';

const LoginEmailSchema = z.object({
  email: RequiredString.email()
});

const ConfirmEmailSchema = LoginEmailSchema.extend({
  code: RequiredString.length(6, {
    message: 'Confirmation Code needs to be 6 digits'
  })
});

const GuestLoginSchema = LoginEmailSchema.extend({
  name: RequiredString
});

const LoginSchema = LoginEmailSchema.extend({
  password: RequiredString
});

const RegisterSchema = LoginSchema.extend({
  confirm: RequiredString
}).refine(({ password, confirm }) => password === confirm, {
  message: 'Password does not match'
});

export {
  ConfirmEmailSchema,
  GuestLoginSchema,
  LoginEmailSchema,
  LoginSchema,
  RegisterSchema
};
